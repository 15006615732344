import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20221103-Rate-Sheet.pdf";
const date = "03.11.2022";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>

        <h1 className="page-title">Changes to our mortgage products</h1>

        <br />
        <p>With effect from Thursday 3 November 2022 we will be making the following changes to our range:</p>
        <p>
          <strong>Summary of changes</strong>
        </p>
        <p>
          <strong>UK Residential Existing Customer Switching Range</strong>
        </p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
          <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
        </ul>
        <p>
          <strong>UK Residential Existing Customer Borrowing More Range</strong>
        </p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
          <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
        </ul>
        <p>
          <strong>UK Residential First Time Buyer Range</strong>
        </p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
          <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
        </ul>
        <p>
          <strong>UK Residential Remortgage Range</strong>
        </p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
          <li>2 Year Fixed Standard at 60% LTV has reduced</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
        </ul>
        <p>
          <strong>UK Residential Home Mover Range</strong>
        </p>
        <ul>
          <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
          <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have reduced</li>
          <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have reduced</li>
        </ul>
        <p>
          <strong>International Residential Range</strong>
        </p>
        <ul>
          <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
          <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have reduced</li>
          <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have reduced</li>
          <li>5 Year Fixed Premier Exclusive at 60%, 70% and 75% LTV have reduced</li>
        </ul>
        <p>
          We have introduced our cashback incentive of £500 to our UK Residential First Time Buyer and Home Mover ranges
          at 60%, 70%, 75% and 80% LTV.
        </p>
        <p>There are no changes to any of our other interest rates or to fixed rate end dates at this time.</p>
        <p>An updated mortgage rate sheet will be published on Thursday 3 November to reflect these changes.</p>

  </NewsArticleLayout>
);

export default Article;
